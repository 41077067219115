@charset "UTF-8";
/*
    Created on : 8 févr. 2016, 17:04:13
    Author     : David
*/
.main-color {
  color: #3277b3; }

.alt-color {
  color: #ffffff; }

.jqx-grid-cell-selected > * > a {
  color: white; }

.row-no-padding {
  margin-left: 0 !important;
  margin-right: 0 !important; }
  .row-no-padding [class*="col-"] {
    padding-left: 0 !important;
    padding-right: 0 !important; }

.inline-block {
  display: inline-block;
  vertical-align: top; }

.inline-block-middle {
  display: inline-block;
  vertical-align: middle; }

.btn-margin {
  margin-right: 3px;
  margin-top: 3px;
  margin-bottom: 3px; }

.overflow-auto {
  overflow: auto; }

.progress-badge {
  background-color: #a5a5a5; }

.overlay-icon {
  position: relative; }

.overlay-icon .after {
  border: 1px solid grey;
  position: absolute;
  top: 25%;
  left: -25%;
  transform: scale(0.5, 0.5);
  display: block;
  background: rgba(0, 0, 0, 0.3); }

/*
    Created on : 29 févr. 2016, 11:14:09
    Author     : David
*/
.default-color-background {
  background-color: #f5f5f5; }

.background-top-right {
  background-repeat: no-repeat;
  background-position: top right; }

.background-top-left {
  background-repeat: no-repeat;
  background-position: top left; }

.background-bottom-left {
  background-repeat: no-repeat;
  background-position: bottom left; }

.background-bottom-right {
  background-repeat: no-repeat;
  background-position: bottom right; }

.background1 {
  background-image: url("../images/background1.png"); }

.background2 {
  background-image: url("../images/background2.png"); }

.background3 {
  background-image: url("../images/background3.png"); }

.background4 {
  background-image: url("../images/FilCouleur.png"); }

@media screen and (max-width: 750px) {
  .background1 {
    background-image: url("../images/background1_sm.png"); }
  .background2 {
    background-image: url("../images/background2_sm.png"); }
  .background3 {
    background-image: url("../images/background3_sm.png"); }
  .background4 {
    background-image: url("../images/FilCouleur_sm.png"); } }

@media screen and (max-width: 600px) {
  .background1 {
    background: none; } }

/*
    Created on : 4 févr. 2016, 09:59:29
    Author     : David
*/
.person-icon {
  background-image: url(../images/sprite.png);
  background-position: -64px -219px;
  width: 64px;
  height: 64px; }

.person-xs-icon {
  background-image: url(../images/sprite.png);
  background-position: -320px -243px;
  width: 24px;
  height: 24px;
  display: inline-block;
  vertical-align: middle; }

.persons-icon {
  background-image: url(../images/sprite.png);
  background-position: -64px -219px;
  width: 64px;
  height: 64px; }

.persons-xs-icon {
  background-image: url(../images/sprite.png);
  background-position: -320px -243px;
  width: 24px;
  height: 24px;
  display: inline-block;
  vertical-align: middle; }

.society-icon {
  background-image: url(../images/sprite.png);
  background-position: -192px -155px;
  width: 64px;
  height: 64px; }

.society-xs-icon {
  background-image: url(../images/sprite.png);
  background-position: -256px -283px;
  width: 24px;
  height: 24px;
  display: inline-block;
  vertical-align: middle; }

.society-xxs-icon {
  background-image: url(../images/sprite.png);
  background-position: -128px -315px;
  width: 16px;
  height: 16px;
  display: inline-block;
  vertical-align: middle; }

.mainevent-icon {
  background-image: url(../images/sprite.png);
  background-position: 0px -155px;
  width: 64px;
  height: 64px; }

.mainevent-xs-icon {
  background-image: url(../images/sprite.png);
  background-position: -320px -155px;
  width: 24px;
  height: 24px;
  display: inline-block;
  vertical-align: middle; }

.mainevent-xxs-icon {
  background-image: url(../images/sprite.png);
  background-position: -320px -267px;
  width: 16px;
  height: 16px;
  display: inline-block;
  vertical-align: middle; }

.project-icon {
  background-image: url(../images/sprite.png);
  background-position: 0px -219px;
  width: 64px;
  height: 64px; }

.project-xs-icon {
  background-image: url(../images/sprite.png);
  background-position: -184px -283px;
  width: 24px;
  height: 24px;
  display: inline-block;
  vertical-align: middle; }

.callforproject-icon {
  background-image: url(../images/sprite.png);
  background-position: -64px -283px;
  width: 64px;
  height: 64px; }

.callforproject-xs-icon {
  background-image: url(../images/sprite.png);
  background-position: -320px -219px;
  width: 24px;
  height: 24px;
  display: inline-block;
  vertical-align: middle; }

.user-icon {
  background-image: url(../images/sprite.png);
  background-position: -192px -219px;
  width: 64px;
  height: 64px; }

.user-xs-icon {
  background-image: url(../images/sprite.png);
  background-position: -208px -283px;
  width: 24px;
  height: 24px;
  display: inline-block;
  vertical-align: middle; }

.eventabstract-icon {
  background-image: url(../images/sprite.png);
  background-position: -256px -155px;
  width: 64px;
  height: 64px; }

.eventabstract-xs-icon {
  background-image: url(../images/sprite.png);
  background-position: -280px -283px;
  width: 24px;
  height: 24px;
  display: inline-block;
  vertical-align: middle; }

.eventabstract-xxs-icon {
  background-image: url(../images/sprite.png);
  background-position: -304px -283px;
  width: 16px;
  height: 16px;
  display: inline-block;
  vertical-align: middle; }

.team-icon {
  background-image: url(../images/sprite.png);
  background-position: -128px -155px;
  width: 64px;
  height: 64px; }

.team-xs-icon {
  background-image: url(../images/sprite.png);
  background-position: -232px -283px;
  width: 24px;
  height: 24px;
  display: inline-block;
  vertical-align: middle; }

.team-xxs-icon {
  background-image: url(../images/sprite.png);
  background-position: -320px -203px;
  width: 16px;
  height: 16px;
  display: inline-block;
  vertical-align: middle; }

.STRUCTURE-icon {
  background-image: url(../images/sprite.png);
  background-position: -192px -155px;
  width: 64px;
  height: 64px; }

.STRUCTURE-xs-icon {
  background-image: url(../images/sprite.png);
  background-position: -256px -283px;
  width: 24px;
  height: 24px;
  display: inline-block;
  vertical-align: middle; }

.STRUCTURE-xxs-icon {
  background-image: url(../images/sprite.png);
  background-position: -128px -315px;
  width: 16px;
  height: 16px;
  display: inline-block;
  vertical-align: middle; }

.ENTITY-icon {
  background-image: url(../images/sprite.png);
  background-position: -128px -219px;
  width: 64px;
  height: 64px; }

.ENTITY-xs-icon {
  background-image: url(../images/sprite.png);
  background-position: -160px -283px;
  width: 24px;
  height: 24px;
  display: inline-block;
  vertical-align: middle; }

.ENTITY-xxs-icon {
  background-image: url(../images/sprite.png);
  background-position: -320px -283px;
  width: 16px;
  height: 16px;
  display: inline-block;
  vertical-align: middle; }

.mailing-icon {
  background-image: url(../images/sprite.png);
  background-position: 0px -283px;
  width: 64px;
  height: 64px; }

/*
    Created on : 10 févr. 2016, 11:46:31
    Author     : David
*/
/* ==========================================================================
Version number in navbar
========================================================================== */
.navbar {
  margin-bottom: 0px; }

.navbar-version {
  font-size: 10px;
  color: #ccc; }

.logo-small {
  background-image: url(../images/sprite.png);
  background-position: -128px -283px;
  width: 32px;
  height: 32px;
  vertical-align: middle;
  margin-right: 5px; }

.navbar-icons {
  padding-top: 8px; }

.navbar-history {
  width: 320px; }
  .navbar-history a {
    max-width: 310px;
    overflow: hidden;
    -ms-text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap; }

.navbar .dropdown-menu {
  z-index: 1000; }

/*
    Created on : 3 févr. 2016, 20:45:25
    Author     : David
*/
.result-thumbnail {
  margin-top: 10px;
  margin-bottom: 10px;
  position: relative;
  display: inline-block;
  border-bottom: 1px solid #31b0d5;
  border-right: 1px solid #31b0d5;
  width: 100%;
  height: 171px;
  overflow-y: auto; }
  @media screen and (max-width: 500px) {
    .result-thumbnail {
      margin-right: 0px; } }
  @media screen and (min-width: 500px) {
    .result-thumbnail {
      vertical-align: top; } }
  .result-thumbnail:hover {
    -webkit-box-shadow: #31b0d5 2px 2px 8px 2px;
    -moz-box-shadow: #31b0d5 2px 2px 8px 2px;
    box-shadow: #31b0d5 2px 2px 8px 2px; }
  .result-thumbnail.selected {
    -webkit-box-shadow: #2490af 3px 3px 10px 3px;
    -moz-box-shadow: #2490af 3px 3px 10px 3px;
    box-shadow: #2490af 3px 3px 10px 3px; }
  .result-thumbnail .thumbnail-picture,
  .result-thumbnail .thumbnail-informations {
    display: inline-block; }
  .result-thumbnail .thumbnail-picture {
    border: 1px solid #2490af;
    vertical-align: top;
    cursor: pointer;
    padding: 5px;
    position: relative; }
  @media screen and (max-width: 500px) {
    .result-thumbnail .thumbnail-picture {
      padding: 5px;
      zoom: 0.8;
      -moz-transform: scale(0.8);
      -moz-transform-origin: 0 0; } }
  .result-thumbnail .thumbnail-informations {
    margin-left: 5px;
    width: 85%; }
    @media screen and (min-width: 1200px) and (max-width: 1500px) {
      .result-thumbnail .thumbnail-informations {
        width: 80%; } }
    @media screen and (max-width: 640px) {
      .result-thumbnail .thumbnail-informations {
        width: 75%; } }
    .result-thumbnail .thumbnail-informations > div {
      margin-bottom: 1px;
      overflow: hidden;
      -ms-text-overflow: ellipsis;
      -o-text-overflow: ellipsis;
      text-overflow: ellipsis;
      white-space: nowrap; }
  .result-thumbnail .thumbnail-identification {
    font-weight: bold;
    font-size: 16px;
    color: #3277b3;
    cursor: pointer; }

.no-result {
  margin-top: 60px;
  height: 400px; }

.no-result-suggestion {
  margin-bottom: 10px; }

.no-result-query {
  font-weight: bold;
  margin-bottom: 20px; }

.no-result-filter {
  margin-bottom: 20px;
  margin-right: 20px; }
  .no-result-filter .aggregation-value {
    cursor: pointer;
    font-size: 14px;
    margin-right: 5px; }

.thumbnail-loc-img {
  position: absolute;
  bottom: 0px;
  right: 1px;
  height: 35px; }

.selection-hidden {
  outline: none;
  border: 0; }

/*
    Created on : 8 févr. 2016, 17:00:04
    Author     : David
*/
.aggregations {
  color: #3277b3;
  padding-top: 10px; }
  .aggregations .aggregation-title {
    font-size: 20px;
    font-weight: bold; }
  .aggregations .aggregation-values {
    max-height: 280px;
    overflow: auto; }
  .aggregations .aggregation-value {
    font-size: 15px;
    color: grey;
    cursor: pointer;
    padding: 2px;
    margin-bottom: 3px; }
    .aggregations .aggregation-value > div:first-child {
      display: inline-block;
      vertical-align: middle; }
    .aggregations .aggregation-value:hover {
      background-color: #3277b3;
      color: #ffffff; }
      .aggregations .aggregation-value:hover .aggregation-count {
        color: #ffffff; }
    .aggregations .aggregation-value .aggregation-count {
      color: #3277b3; }
  .aggregations .aggregation-filtered {
    background-color: #3277b3;
    color: #ffffff; }
    .aggregations .aggregation-filtered .aggregation-count {
      color: #ffffff; }
  .aggregations .aggregation-not-filtered {
    display: none; }

.aggregation-name {
  font-size: 18px;
  font-weight: bold;
  border-bottom: 1px solid #3277b3;
  margin-bottom: 5px;
  margin-top: 10px; }

/*
    Created on : 9 févr. 2016, 21:21:36
    Author     : David
*/
.search-result-bar {
  height: 35px;
  line-height: 35px; }
  .search-result-bar .search-criterion {
    color: #3277b3; }
  .search-result-bar .criteria {
    color: #3277b3; }
    .search-result-bar .criteria .criterion:hover {
      font-style: italic;
      text-decoration: underline; }
    .search-result-bar .criteria span::before {
      display: none; }
    .search-result-bar .criteria:hover span::before {
      display: block; }
  @media screen and (max-width: 480px) {
    .search-result-bar .response-time {
      display: none; }
    .search-result-bar .reminder {
      display: none; } }

/*
    Created on : 20 avr. 2016, 16:02:57
    Author     : David
*/
.timeline {
  list-style: none;
  padding: 20px 0 20px;
  position: relative; }
  .timeline:before {
    top: 0;
    bottom: 0;
    position: absolute;
    content: " ";
    width: 3px;
    background-color: #eeeeee;
    left: 50%;
    margin-left: -1.5px; }
  .timeline > li {
    margin-bottom: 10px;
    position: relative; }
    .timeline > li:before, .timeline > li:after {
      content: " ";
      display: table; }
    .timeline > li:after {
      clear: both; }
    .timeline > li > .timeline-panel {
      min-height: 80px;
      width: 46%;
      float: left;
      border: 1px solid #d4d4d4;
      border-radius: 2px;
      padding: 12px;
      position: relative;
      -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
      box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175); }
      .timeline > li > .timeline-panel:before {
        position: absolute;
        top: 26px;
        right: -15px;
        display: inline-block;
        border-top: 15px solid transparent;
        border-left: 15px solid #ccc;
        border-right: 0 solid #ccc;
        border-bottom: 15px solid transparent;
        content: " "; }
      .timeline > li > .timeline-panel:after {
        position: absolute;
        top: 27px;
        right: -14px;
        display: inline-block;
        border-top: 14px solid transparent;
        border-left: 14px solid #fff;
        border-right: 0 solid #fff;
        border-bottom: 14px solid transparent;
        content: " "; }
    .timeline > li > .timeline-badge {
      color: #fff;
      width: 32px;
      height: 32px;
      line-height: 35px;
      font-size: 1.3em;
      text-align: center;
      position: absolute;
      top: 26px;
      left: 50%;
      margin-left: -17px;
      background-color: #999999;
      z-index: 100;
      border-top-right-radius: 50%;
      border-top-left-radius: 50%;
      border-bottom-right-radius: 50%;
      border-bottom-left-radius: 50%; }
    .timeline > li.timeline-inverted > .timeline-panel {
      float: right; }
      .timeline > li.timeline-inverted > .timeline-panel:before {
        border-left-width: 0;
        border-right-width: 15px;
        left: -15px;
        right: auto; }
      .timeline > li.timeline-inverted > .timeline-panel:after {
        border-left-width: 0;
        border-right-width: 14px;
        left: -14px;
        right: auto; }

.timeline-badge.primary {
  background-color: #2e6da4 !important; }

.timeline-badge.success {
  background-color: #3f903f !important; }

.timeline-badge.warning {
  background-color: #f0ad4e !important; }

.timeline-badge.danger {
  background-color: #d9534f !important; }

.timeline-badge.info {
  background-color: #5bc0de !important; }

.timeline-title {
  margin-top: 0;
  color: inherit;
  font-size: 13px; }

.timeline-body > p, .timeline-body > ul {
  margin-bottom: 0; }

.timeline-body > p + p {
  margin-top: 5px; }

@media (max-width: 767px) {
  ul.timeline:before {
    left: 40px; }
  ul.timeline > li > .timeline-panel {
    width: calc(100% - 90px);
    width: -moz-calc(100% - 90px);
    width: -webkit-calc(100% - 90px);
    float: right; }
    ul.timeline > li > .timeline-panel:before {
      border-left-width: 0;
      border-right-width: 15px;
      left: -15px;
      right: auto; }
    ul.timeline > li > .timeline-panel:after {
      border-left-width: 0;
      border-right-width: 14px;
      left: -14px;
      right: auto; }
  ul.timeline > li > .timeline-badge {
    left: 17px;
    margin-left: 0;
    top: 26px; } }

/*
    Created on : 12 juil. 2016, 12:04:31
    Author     : David
*/
.character-box {
  display: inline-table;
  width: 80px;
  border: 1px solid #DDD; }

.simple-character-box {
  height: 40px;
  line-height: 40px; }

.double-character-box {
  height: 50px;
  line-height: 30px; }

.character-content {
  cursor: pointer; }
  .character-content:hover {
    font-weight: bold;
    font-size: larger; }

.dl-horizontal.dl-center {
  margin-top: 5px; }
  .dl-horizontal.dl-center > dd {
    margin-left: 50%; }
  .dl-horizontal.dl-center > dt {
    width: 47%; }
  .dl-horizontal.dl-center > dt, .dl-horizontal.dl-center > dd {
    margin-bottom: 2px; }

.bs-callout {
  padding: 20px;
  margin: 20px 0;
  border: 1px solid #eee;
  border-left-width: 5px;
  border-radius: 3px; }

.bs-callout h4 {
  margin-top: 0;
  margin-bottom: 5px; }

.bs-callout p:last-child {
  margin-bottom: 0; }

.bs-callout code {
  border-radius: 3px; }

.bs-callout + .bs-callout {
  margin-top: -5px; }

.bs-callout-default {
  border-left-color: #777; }

.bs-callout-default h4 {
  color: #777; }

.bs-callout-primary {
  border-left-color: #428bca; }

.bs-callout-primary h4 {
  color: #428bca; }

.bs-callout-success {
  border-left-color: #5cb85c; }

.bs-callout-success h4 {
  color: #5cb85c; }

.bs-callout-danger {
  border-left-color: #d9534f; }

.bs-callout-danger h4 {
  color: #d9534f; }

.bs-callout-warning {
  border-left-color: #f0ad4e; }

.bs-callout-warning h4 {
  color: #f0ad4e; }

.bs-callout-info {
  border-left-color: #5bc0de; }

.bs-callout-info h4 {
  color: #5bc0de; }

/*
    Created on : 13 mars 2017, 20:44:51
    Author     : David
*/
.upload-file .progress {
  height: 30px;
  margin-right: 5px;
  margin-left: 5px;
  margin-top: 3px;
  margin-bottom: 10px; }
  .upload-file .progress > .progress-bar {
    padding-top: 3px; }

/*
    Created on : 19 févr. 2016, 10:11:25
    Author     : David
*/
.elasticsearch-logo {
  background-image: url(../images/sprite.png);
  background-position: -64px -155px;
  width: 64px;
  height: 64px;
  display: inline-block;
  vertical-align: middle; }

.elasticsearch .elasticsearch-section {
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 5px;
  padding-bottom: 5px; }

.elasticsearch .elasticsearch-title {
  border-bottom: 1px solid #3277b3;
  padding-bottom: 5px;
  margin-bottom: 15px;
  margin-top: 5px; }

.elasticsearch > div {
  min-height: 200px; }

.elasticsearch .elasticsearch-errors {
  font-size: 20px;
  font-weight: bold; }

/*
    Created on : 15 févr. 2016, 15:43:20
    Author     : David
*/
.sheet {
  padding-right: 0px;
  margin-top: 10px; }

.sheet-toolbar {
  margin-right: 5px;
  margin-left: 0px;
  height: 40px;
  padding-top: 5px;
  margin-bottom: 10px; }

.sheet-expert-toolbar {
  margin-right: 5px;
  margin-left: 0px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: 10px; }
  .sheet-expert-toolbar .form-group {
    margin-right: 10px; }

.sheet-tool {
  font-size: 16px;
  float: right;
  cursor: pointer;
  margin-left: 8px;
  padding: 2px;
  z-index: 100; }
  .sheet-tool.glyphicon:hover {
    color: #3277b3; }

.panel-primary .sheet-tool.glyphicon:hover {
  color: #cccccc; }

.panel-title, .sheet-title {
  font-size: 18px; }
  .panel-title > form > div > input, .sheet-title > form > div > input {
    min-width: 400px; }
  .panel-title .icon, .sheet-title .icon {
    display: inline-block;
    vertical-align: middle; }

.sheet-title {
  font-size: 28px; }
  @media screen and (min-width: 0px) and (max-width: 768px) {
    .sheet-title {
      font-size: 22px; } }
  @media screen and (min-width: 769px) and (max-width: 991px) {
    .sheet-title {
      font-size: 24px; } }

.sheet-content {
  margin-top: 10px; }

.sheet-avatar-icon {
  height: 72px;
  margin-bottom: 5px; }

.search-avatar-icon {
  height: 64px;
  width: 64px; }

.sheet-bloc {
  border: 1px solid #e0e0e0;
  background-color: white;
  margin-top: 10px; }
  .sheet-bloc .form-group {
    margin-bottom: 2px;
    margin-left: 0px;
    margin-right: 2px; }
  .sheet-bloc .input-group-sm {
    margin-bottom: 5px; }
  .sheet-bloc input {
    margin-bottom: 2px; }
    @media screen and (min-width: 600px) {
      .sheet-bloc input {
        display: inline-block; } }

.sheet-bloc-title {
  font-size: 18px;
  font-weight: bold;
  background-color: #f7f7f7;
  border-bottom: 1px solid #e0e0e0;
  padding-top: 5px;
  padding-left: 10px;
  padding-right: 5px;
  padding-bottom: 5px; }

.sheet-bloc-content {
  padding: 10px;
  padding-top: 5px; }

.sheet-bloc-form {
  margin-bottom: 5px; }
  .sheet-bloc-form > label {
    min-width: 130px; }

.control-label {
  margin-right: 5px; }

.form-horizontal .control-label.text-left {
  text-align: left; }

.location-information {
  margin: 5px; }

.form-custom-inline-group {
  display: inline-block;
  width: 195px;
  margin-top: 10px; }
  .form-custom-inline-group > label {
    vertical-align: top; }
  .form-custom-inline-group > span {
    display: inline-block; }

.form-horizontal .form-group.form-custom-inline-group {
  margin-right: 0px; }

/*
    Created on : 10 févr. 2016, 15:35:42
    Author     : David
*/
.sheet-person > .page-header {
  margin-top: 10px; }

.sheet-person .sheet-bloc-contact .form-group {
  margin-bottom: 5px; }
  .sheet-person .sheet-bloc-contact .form-group input {
    margin-bottom: 2px; }
  .sheet-person .sheet-bloc-contact .form-group .cp {
    width: 25%;
    display: inline-block; }
  .sheet-person .sheet-bloc-contact .form-group .city {
    width: 74%;
    display: inline-block; }

.sheet-person .sheet-bloc-description {
  min-height: 200px; }

.sheet-person .sheet-bloc-map input {
  margin-bottom: 2px;
  display: block; }

/*
    Created on : 10 févr. 2016, 15:35:42
    Author     : David
*/
.sheet-society .form-group {
  margin-bottom: 5px; }
  .sheet-society .form-group .form-control {
    margin-bottom: 2px; }

.sheet-society .sheet-bloc-description, .sheet-society .sheet-bloc-otherinformation {
  min-height: 200px; }

.sheet-society .sheet-bloc-map input {
  margin-bottom: 2px;
  display: block; }

/*
    Created on : 16 mars 2016, 14:09:55
    Author     : David
*/
.sheet-mainevent > .page-header {
  margin-top: 10px; }

.sheet-mainevent .sheet-bloc-informations .form-group {
  margin-bottom: 5px; }

.sheet-mainevent .sheet-bloc-informations .start-end-dates > .jqx-input {
  display: inline-block;
  vertical-align: top;
  margin-top: 3px; }

.sheet-mainevent .contacts-list-edition {
  display: inline-block;
  margin-right: 5px;
  white-space: nowrap;
  line-height: 20px;
  margin-bottom: 5px;
  cursor: pointer; }
  .sheet-mainevent .contacts-list-edition > span:first-child {
    margin-right: 5px; }
    .sheet-mainevent .contacts-list-edition > span:first-child:hover {
      text-decoration: underline; }
  .sheet-mainevent .contacts-list-edition > button {
    float: none;
    vertical-align: top; }

.sheet-mainevent .sheet-bloc-participations .participation-count {
  float: right;
  padding-top: 5px; }
  .sheet-mainevent .sheet-bloc-participations .participation-count > div {
    padding-top: 0px;
    margin-right: 15px; }

.sheet-mainevent .sheet-bloc-participations .jqx-grid {
  margin-top: 10px; }

.sheet-mainevent .sheet-bloc-description {
  min-height: 250px; }

.sheet-mainevent .sheet-bloc-map input {
  margin-bottom: 2px;
  display: block; }

.sheet-mainevent .tab-content {
  margin-top: 10px; }

.participation-toolbar {
  margin-bottom: 5px;
  height: 40px; }

.sheet-mainevent-tab {
  font-size: 16px; }

.column-images > span {
  margin-right: 2px; }

.add-mainevent-checkboxes > label {
  min-width: 70px;
  margin-right: 0px; }

.add-mainevent-checkboxes > span {
  margin-right: 10px; }

.moderator {
  margin-bottom: 5px; }

.moderator-name {
  display: inline-block;
  min-width: 180px; }

/*
    Created on : 26 avr. 2016, 09:54:56
    Author     : David
*/
.sheet-project .sheet-bloc-description {
  min-height: 200px; }

/*
    Created on : 12 mai 2016, 10:40:10
    Author     : David
*/
#callforproject-total {
  padding: 10px; }
  #callforproject-total > div > blockquote {
    display: inline-block;
    font-size: 16px; }

.callforproject-charts {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px; }
  .callforproject-charts > div {
    display: inline-block; }

.chart-inner-text {
  font-size: 30px;
  font-family: Verdana; }

.bareme {
  display: inline-block;
  vertical-align: middle;
  font-size: 16px; }

.expert-space .cfp-form-project .form-group {
  margin-bottom: 5px; }

.cfp-project-title {
  margin-top: 5px;
  margin-bottom: 20px; }

.sheet-eventabstract > .page-header {
  margin-top: 10px; }

.form-group.abstract-author {
  margin-bottom: 10px; }

.table-abstract-type td, .table-abstract-type th {
  text-align: center; }

/*
    Created on : 9 juin 2016, 20:02:52
    Author     : David
*/
.home-events {
  min-height: 200px; }

.home-events-panel-title {
  font-size: 18px;
  font-weight: bold; }

.home-events-title {
  font-weight: bold;
  font-size: 16px;
  border-bottom: 1px solid #FF0000;
  margin-bottom: 10px; }

.home-events-future {
  margin-top: 10px;
  margin-bottom: 10px; }

.home-events-open > .home-events-title {
  border-bottom: 1px solid #33CC33; }

.home-events-item {
  margin-bottom: 2px; }

/*
    Created on : 3 févr. 2016, 09:14:12
    Author     : David
*/
.person-tab-header > th {
  white-space: nowrap;
  max-width: 80px;
  overflow: hidden;
  -ms-text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap; }

.person-lastName-tab {
  max-width: 120px;
  overflow: hidden;
  -ms-text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap; }

.person-email-tab {
  max-width: 120px;
  overflow: hidden;
  -ms-text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap; }

.person-address-tab {
  max-width: 120px;
  overflow: hidden;
  -ms-text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap; }

.person-address2-tab {
  max-width: 120px;
  overflow: hidden;
  -ms-text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap; }

.person-city-tab {
  max-width: 120px;
  overflow: hidden;
  -ms-text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap; }

.person-phone-tab {
  max-width: 120px;
  overflow: hidden;
  -ms-text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap; }

.person-portable-tab {
  max-width: 120px;
  overflow: hidden;
  -ms-text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap; }

.person-fax-tab {
  max-width: 120px;
  overflow: hidden;
  -ms-text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap; }

.person-description-tab {
  max-width: 120px;
  overflow: hidden;
  -ms-text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap; }

.person-privateDescription-tab {
  max-width: 120px;
  overflow: hidden;
  -ms-text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap; }

/*
    Created on : 27 avr. 2016, 11:48:14
    Author     : David
*/
.axes-title {
  height: 45px;
  font-size: 30px;
  display: inline-block;
  vertical-align: bottom;
  margin-right: 40px; }

#axes-content {
  margin-top: 20px; }
  @media screen and (min-width: 750px) {
    #axes-content .nav-tabs {
      display: flex; }
      #axes-content .nav-tabs li {
        display: flex;
        flex: 1; }
        #axes-content .nav-tabs li a {
          flex: 1; } }
  #axes-content .nav-stacked {
    display: inline-block;
    width: 40%; }
  #axes-content .tab-content {
    display: inline-block;
    width: 50%;
    vertical-align: top;
    margin-left: 10px; }

.axes-title-content {
  display: inline-block;
  vertical-align: bottom; }
  .axes-title-content > div {
    display: inline-block; }

/*
    Created on : 5 mars 2017, 21:24:14
    Author     : David
*/
.groups-title {
  height: 45px;
  font-size: 30px;
  display: inline-block;
  vertical-align: bottom;
  margin-right: 40px; }

#groups-content {
  margin-top: 20px; }
  @media screen and (min-width: 750px) {
    #groups-content .nav-tabs {
      display: flex; }
      #groups-content .nav-tabs li {
        display: flex;
        flex: 1; }
        #groups-content .nav-tabs li a {
          flex: 1; } }

/*
    Created on : 9 févr. 2016, 22:07:01
    Author     : David
*/
.search {
  margin-top: 10px; }
  .search .search-title {
    height: 28px;
    line-height: 28px;
    font-size: 28px; }
  .search .search-content {
    margin-top: 20px;
    border: 1px solid #e0e0e0; }
  .search .search-result {
    border-left: 1px solid #e0e0e0;
    padding-left: 0px;
    padding-right: 0px; }
    .search .search-result > div:first-child {
      background-color: #F8F8F8;
      border-bottom: 1px solid #e7e7e7; }
    .search .search-result > .search-result-content {
      min-height: 800px; }
  @media screen and (max-width: 380px) {
    .search .search-result > div {
      padding-left: 5px;
      padding-right: 5px; } }
  @media screen and (min-width: 380px) {
    .search .search-result > div:not(:first-child) {
      padding-right: 0px; } }

/*
    Created on : 25 mars 2016, 09:08:23
    Author     : David
*/
.manifestation-title {
  height: 45px;
  font-size: 30px;
  display: inline-block;
  vertical-align: bottom;
  margin-right: 40px; }

.manifestation-content {
  margin-top: 20px; }

.manifestation-scheduler {
  max-width: 1000px; }

.manifestation-title-content {
  display: inline-block;
  vertical-align: bottom; }
  .manifestation-title-content > div {
    display: inline-block; }

.manifestation-searchbar {
  max-width: 400px; }

.manifestation-description img {
  max-width: 100%; }

.manifestation-details-title {
  cursor: pointer; }
  .manifestation-details-title > h4:hover {
    text-decoration: underline; }

.manifestation-sessions {
  margin-top: 20px;
  min-height: 200px; }

.speaker {
  font-weight: bold; }

.export-handle {
  cursor: move;
  text-align: center; }

.through {
  text-decoration: line-through; }

.abstract-person-edit {
  margin-top: 5px;
  margin-right: 5px;
  cursor: move;
  width: 280px; }

.abstract-person-edit-tools {
  float: right; }

/*
    Created on : 9 juin 2016, 13:11:21
    Author     : David
*/
#subscription {
  min-height: 600px; }

.btn.btn-subscription {
  cursor: pointer; }

.subscription-title {
  font-size: 24px;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px; }

.subscription-event-title {
  font-size: 24px;
  font-weight: bold; }
  .subscription-event-title > * {
    display: inline-block;
    vertical-align: middle; }

.subscription-legend {
  font-size: 18px; }

.subscription-legend-date {
  font-weight: bold; }

.subscription-info-group {
  margin-top: 5px;
  margin-bottom: 10px; }

.subscription-limits-date {
  margin-bottom: 5px; }
  .subscription-limits-date > label {
    width: 55%;
    text-align: right;
    margin-right: 5px;
    display: inline-block;
    vertical-align: top; }
    @media screen and (min-width: 992px) and (max-width: 1180px) {
      .subscription-limits-date > label {
        width: 60%; } }
  .subscription-limits-date > span {
    font-size: 16px;
    color: orange;
    display: inline-block;
    vertical-align: top; }

.tarifs-table td, .tarifs-table th {
  vertical-align: middle !important;
  text-align: center; }

.subscription-form {
  margin-top: 15px; }

.subscription-panel-title {
  font-size: 18px; }

.subscription-validation-legend {
  font-size: 18px; }

.form-group.required .control-label:after {
  content: "*";
  color: red; }

.sessions-days {
  display: inline-block;
  vertical-align: top;
  margin-right: 20px;
  width: 450px; }
  .sessions-days .panel-body {
    padding-top: 0px; }

.range-title {
  font-size: 15px;
  margin-bottom: 10px;
  margin-top: 10px;
  font-weight: bold; }

.range-sessions {
  margin-left: 10px; }
  .range-sessions .radio {
    margin-top: -5px; }

.subscription-confirm.container-fluid {
  height: 600px;
  display: table;
  width: 100%;
  padding: 0; }
  .subscription-confirm.container-fluid > .row-fluid {
    height: 100%;
    display: table-cell;
    vertical-align: middle; }

.subscription-confirm-content {
  margin: 20px; }

.subscription-alert {
  width: 80%;
  padding: 10px;
  margin-top: 10px; }
  .subscription-alert > span {
    font-size: 18px;
    font-weight: bold; }

.abstract-informations {
  font-weight: bold; }

.abstract-people, .abstract-societies {
  margin-top: 20px;
  margin-bottom: 10px; }

.abstract-speaker {
  font-weight: bold; }

.abstract-person {
  margin-right: 10px; }

.registration-session {
  padding-top: 5px;
  padding-bottom: 5px; }

.subscription-space .form-group {
  margin-bottom: 5px; }

.subscription-space .mail-date-bloc > div {
  margin-top: 1rem; }

.subscription-space .mail-date-bloc .mail-date > div {
  margin-bottom: 0.5rem; }

.expert-space .form-group {
  margin-bottom: 0px; }

.form-group.inline-block {
  display: inline-block;
  margin-right: 10px; }

body {
  background: #fafafa;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  color: #333; }

/* ==========================================================================
Hide ng-cloak on page load, https://docs.angularjs.org/api/ng/directive/ngCloak
========================================================================== */
[ng\:cloak],
[ng-cloak],
[data-ng-cloak],
[x-ng-cloak],
.ng-cloak,
.x-ng-cloak {
  display: none !important; }

/* ==========================================================================
Developement Ribbon
========================================================================== */
.ribbon {
  background-color: #a00;
  box-shadow: 0 0 10px #888;
  left: -3.5em;
  moz-box-shadow: 0 0 10px #888;
  moz-transform: rotate(-45deg);
  ms-transform: rotate(-45deg);
  o-transform: rotate(-45deg);
  overflow: hidden;
  position: fixed;
  top: 40px;
  transform: rotate(-45deg);
  webkit-box-shadow: 0 0 10px #888;
  webkit-transform: rotate(-45deg);
  white-space: nowrap;
  width: 15em;
  z-index: 9999;
  pointer-events: none; }
  .ribbon a {
    border: 1px solid #faa;
    color: #fff;
    display: block;
    font: bold 81.25% 'Helvetica Neue', Helvetica, Arial, sans-serif;
    margin: 1px 0;
    padding: 10px 50px;
    text-align: center;
    text-decoration: none;
    text-shadow: 0 0 5px #444;
    pointer-events: none; }

/* ==========================================================================
Browser Upgrade Prompt
========================================================================== */
.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0; }

.container-content {
  padding-right: 0px; }

.container-fluid {
  padding-left: 0px; }

/* ==========================================================================
Main page styles
========================================================================== */
.hero-unit {
  margin: 50px auto 0 auto;
  width: 300px;
  font-size: 18px;
  font-weight: 200;
  line-height: 30px;
  background-color: #eee;
  border-radius: 6px;
  padding: 60px; }
  .hero-unit h1 {
    font-size: 60px;
    line-height: 1;
    letter-spacing: -1px; }

.error {
  color: white;
  background-color: red; }

.main-logo {
  display: inline-block;
  width: 350px;
  height: 155px;
  background: url("../images/logo.png") no-repeat center top;
  background-size: contain; }

[ng-cloak].splash {
  display: block !important; }

.splash {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); }

.main-content {
  min-height: 700px;
  padding-right: 15px;
  padding-left: 15px; }

.pad {
  padding: 10px; }

.break {
  white-space: normal;
  word-break: break-all; }

.disabled {
  opacity: 0.6;
  pointer-events: none; }
  .disabled > * {
    opacity: 0.6;
    pointer-events: none; }

/* wait autoprefixer update to allow simple generation of high pixel density media query */
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  .main-logo {
    background: url("../images/logo2x.png") no-repeat center top;
    background-size: contain; } }

/* ==========================================================================
Generic styles
========================================================================== */
.voffset {
  margin-top: 2px; }

.voffset1 {
  margin-top: 5px; }

.voffset2 {
  margin-top: 10px; }

.voffset3 {
  margin-top: 15px; }

.voffset4 {
  margin-top: 30px; }

.voffset5 {
  margin-top: 40px; }

.voffset6 {
  margin-top: 60px; }

.voffset7 {
  margin-top: 80px; }

.voffset8 {
  margin-top: 100px; }

.voffset9 {
  margin-top: 150px; }

.readonly {
  background-color: #eee;
  opacity: 1; }

/* ==========================================================================
make sure browsers use the pointer cursor for anchors, even with no href
========================================================================== */
a:hover {
  cursor: pointer; }

.hand {
  cursor: pointer; }

/* ==========================================================================
Metrics and Health styles
========================================================================== */
#threadDump .popover,
#healthCheck .popover {
  top: inherit;
  display: block;
  font-size: 10px;
  max-width: 1024px; }

#healthCheck .popover {
  margin-left: -50px; }

.health-details {
  min-width: 400px; }

/* ==========================================================================
start Password strength bar style
========================================================================== */
ul#strength {
  display: inline;
  list-style: none;
  margin: 0;
  margin-left: 15px;
  padding: 0;
  vertical-align: 2px; }

.point {
  background: #ddd;
  border-radius: 2px;
  display: inline-block;
  height: 5px;
  margin-right: 1px;
  width: 20px; }
  .point:last {
    margin: 0 !important; }

/* ==========================================================================
Custom alerts for notification
========================================================================== */
.alerts .alert {
  text-overflow: ellipsis; }
  .alerts .alert pre {
    background: none;
    border: none;
    font: inherit;
    color: inherit;
    padding: 0;
    margin: 0; }
  .alerts .alert .popover pre {
    font-size: 10px; }

.alerts .toast {
  position: fixed;
  width: 100%;
  z-index: 10000; }
  .alerts .toast.left {
    left: 5px; }
  .alerts .toast.right {
    right: 5px; }
  .alerts .toast.top {
    top: 55px; }
  .alerts .toast.bottom {
    bottom: 55px; }

@media screen and (min-width: 800px) {
  .alerts .toast {
    width: 50%; } }

.footer {
  text-align: center; }

/* ==========================================================================
entity tables helpers
========================================================================== */
/* Remove Bootstrap padding from the element
   http://stackoverflow.com/questions/19562903/remove-padding-from-columns-in-bootstrap-3 */
.no-padding-left {
  padding-left: 0 !important; }

.no-padding-right {
  padding-right: 0 !important; }

.no-padding-top {
  padding-top: 0 !important; }

.no-padding-bottom {
  padding-bottom: 0 !important; }

.no-padding {
  padding: 0 !important; }

/* bootstrap 3 input-group 100% width
   http://stackoverflow.com/questions/23436430/bootstrap-3-input-group-100-width */
.width-min {
  width: 1% !important; }

/* Makes toolbar not wrap on smaller screens
   http://www.sketchingwithcss.com/samplechapter/cheatsheet.html#right */
.flex-btn-group-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: flex-end;
  justify-content: flex-end; }

.jh-table > tbody > tr > td {
  /* Align text in td verifically (top aligned by Bootstrap) */
  vertical-align: middle; }

.jh-table > thead > tr > th > .glyphicon-sort,
.jh-table > thead > tr > th > .glyphicon-sort-by-attributes,
.jh-table > thead > tr > th > .glyphicon-sort-by-attributes-alt {
  /* less visible sorting icons */
  opacity: 0.5; }
  .jh-table > thead > tr > th > .glyphicon-sort:hover,
  .jh-table > thead > tr > th > .glyphicon-sort-by-attributes:hover,
  .jh-table > thead > tr > th > .glyphicon-sort-by-attributes-alt:hover {
    /* full visible sorting icons and pointer when mouse is over them */
    opacity: 1;
    cursor: pointer; }

/* ==========================================================================
entity detail page css
========================================================================== */
.dl-horizontal.jh-entity-details > dd {
  margin-bottom: 15px; }

@media screen and (min-width: 768px) {
  .dl-horizontal.jh-entity-details > dt {
    margin-bottom: 15px; }
  .dl-horizontal.jh-entity-details > dd {
    border-bottom: 1px solid #eee;
    padding-left: 180px;
    margin-left: 0; } }

/* ==========================================================================
ui bootstrap tweaks
========================================================================== */
.nav,
.pagination,
.carousel,
.panel-title a {
  cursor: pointer; }

.datetime-picker-dropdown > li.date-picker-menu div > table .btn-default,
.uib-datepicker-popup > li > div.uib-datepicker > table .btn-default {
  border: 0; }

.datetime-picker-dropdown > li.date-picker-menu div > table:focus,
.uib-datepicker-popup > li > div.uib-datepicker > table:focus {
  outline: none; }

.btn-group > .btn-success {
  background-color: #50b250; }
  .btn-group > .btn-success > span {
    font-size: smaller; }
  .btn-group > .btn-success.active {
    background-color: #2f6d2f; }
    .btn-group > .btn-success.active > span {
      font-size: inherit; }

.btn-group > .btn-info {
  background-color: #3d87c8; }
  .btn-group > .btn-info > span {
    font-size: smaller; }
  .btn-group > .btn-info.active {
    background-color: #23537c; }
    .btn-group > .btn-info.active > span {
      font-size: inherit; }

.form-validity-error {
  position: fixed;
  right: 4px;
  top: 60px;
  width: 24%;
  z-index: 10; }

/* jhipster-needle-scss-add-main JHipster will add new css style */
.bold {
  font-weight: bold; }

.jqx-grid-cell-filter {
  background-color: rgba(252, 241, 169, 0.5); }
;